import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  mainActiveTab: "integration",
  workspaceName: "",
  brandData: {
    primary_color: "",
    accent_color: "",
    font_color: "",
    logo_url: "",
    font: "",
    heading_case_details: {
      h1: {
        font_name: "",
        case: ""
      },
      h2: {
        font_name: "",
        case: ""
      }
    },
    custom_font: "",
    brand_hashtags: "",
    brand_website: "",
    brand_handle: "",
    dark_logo_url: "",
    tonality: "",
    user_timezone: localStorage.getItem("user_timezone") || "",
    brand_timezone: localStorage.getItem("brand_timezone") || "",
    diplay_timezone: localStorage.getItem("display_timezone") || "",
    name: "",
    brand_palette_colors: undefined,
    brand_colors: [],
    // brand_pallete_colors: {},
    brand_gradient: { degree: "", stops: [] },
    font_color_variants: {
      light_bg: "",
      dark_bg: ""
    },
    shopify_url: "",
    shopify_integration: false,
    ecom_integration: false,
    ecom_store: {},
    ecom_url: "",
    store_name: "",
    woocommerce_display_filename: "",
    other_ecom_display_filename: ""
  },
  brandAccounts: {},
  brandLoading: false,
  deleteAssetIds: [],
  assetSizes: {},
  handlesData: {
    ig: {},
    fb: {},
    fbg: {},
    li: {},
    pi: {},
    gmb: {},
    tt: {},
    twt: {},
    yt: {}
  },
  appId: "",
  apiKeys: {
    ssoKey: "",
    apiKey: ""
  },
  apiUrls: [],
  webhookUrl: ""
};

const setWebhookUrl = (state, action) => {
  state.webhookUrl = action.payload;
};
const setApiUrls = (state, action) => {
  state.apiUrls = action.payload;
};
const setApiKeys = (state, action) => {
  state.apiKeys[action.keyType] = action.payload;
};
const setAppId = (state, action) => {
  state.appId = action.payload;
};
const setBrandAccounts = (state, action) => {
  state.brandAccounts = action.payload;
};
const setAssetSizes = (state, action) => {
  state.assetSizes = action.payload;
};
const setDeleteAssetIds = (state, action) => {
  state.deleteAssetIds = action.payload;
};
const setHandlesData = (state, action) => {
  state.handlesData[action.platform] = action.payload;
};
const setBrandLoading = (state, action) => {
  state.brandLoading = action.payload;
};
const setMainActiveTab = (state, action) => {
  state.mainActiveTab = action.payload;
};
const setBrandData = (state, action) => {
  state.brandData[action.property] = action.payload;
};

const setHeadingCaseDetails = (state, action) => {
  const { level, fontName, fontCase, fontWeight } = action.payload;
  state.brandData.heading_case_details[level] = {
    font_name: fontName,
    case: fontCase,
    font_weight: fontWeight
  };
};

const resetStates = () => initialState;

const workspacesStatesSlice = createSlice({
  name: "workspacesStates",
  initialState,
  reducers: {
    setHeadingCaseDetails,
    setBrandLoading,
    setMainActiveTab,
    setBrandData,
    setHandlesData,
    setBrandAccounts,
    setDeleteAssetIds,
    setAssetSizes,
    setApiKeys,
    setApiUrls,
    setAppId,
    setWebhookUrl,
    resetStates
  }
});

export default workspacesStatesSlice.reducer;
